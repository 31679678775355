.form-control,
.form-label {
  font-size: 14px !important;
}

.form-control {
  height: 30px !important;
  margin-top: 2px;
}

.text-aqua {
  color: rgb(86, 204, 242) !important;
}
.text-green {
  color: rgb(66, 197, 61) !important;
}

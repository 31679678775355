.btn:focus {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
}

button.btn {
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}

button.btn-round {
  border-radius: 15px;
  min-width: 80px;
}

button.btn-purple {
  color: white;
  background: rgb(56, 47, 116);
  border-color: rgb(56, 47, 116);
}
button.btn-purple:hover {
  color: white !important;
  background: rgba(56, 47, 116, 0.8) !important;
  border-color: transparent;
}
button.btn-purple:focus,
button.btn-purple:active,
button.btn-purple.active {
  color: white !important;
  background: rgba(56, 47, 116);
  border-color: transparent;
}
button.btn-purple:disabled {
  color: white;
  background: rgba(56, 47, 116, 0.6);
  border-color: rgba(56, 47, 116, 0.6);
  cursor: not-allowed !important;
}

button.btn-aqua {
  color: white;
  background: rgb(86, 204, 242);
  border-color: rgb(86, 204, 242);
}
button.btn-aqua:hover,
button.btn-aqua:focus,
button.btn-aqua:active,
button.btn-aqua.active {
  color: white !important;
  background: rgba(86, 204, 242, 0.8) !important;
  border-color: transparent !important;
}
button.btn-aqua:disabled {
  color: white;
  background: rgba(86, 204, 242, 0.6);
  border-color: rgba(86, 204, 242, 0.6);
  cursor: not-allowed !important;
}

button.btn-green {
  color: rgb(92, 132, 127);
  background: rgb(152, 208, 198);
  border-color: rgb(152, 208, 198);
}
button.btn-green:hover,
button.btn-green:focus,
button.btn-green:active,
button.btn-green.active {
  color: rgb(92, 132, 127) !important;
  background: rgba(152, 208, 198, 0.8) !important;
  border-color: transparent !important;
}
button.btn-green:disabled {
  color: rgb(92, 132, 127);
  background: rgba(152, 208, 198, 0.6);
  border-color: rgba(152, 208, 198, 0.6);
  cursor: not-allowed !important;
}

button.btn-red {
  color: rgb(226, 35, 77);
  background: rgb(226, 167, 181);
  border-color: rgb(226, 167, 181);
}
button.btn-red:hover,
button.btn-red:focus,
button.btn-red:active,
button.btn-red.active {
  color: rgb(226, 35, 77) !important;
  background: rgba(226, 167, 181, 0.8) !important;
  border-color: transparent !important;
}
button.btn-red:disabled {
  color: rgb(226, 35, 77);
  background: rgba(226, 167, 181, 0.6);
  border-color: rgba(226, 167, 181, 0.6);
  cursor: not-allowed !important;
}

.btn-white {
  color: #888 !important;
  background: white !important;
  border-color: #ccc !important;
}
/* .btn-white:focus, */
.btn-white:active,
.btn-white.active {
  color: #333 !important;
  background: #ccc !important;
  border-color: #ccc !important;

  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
}
.btn-white:hover {
  color: #333 !important;
  background: #eaeaea !important;
  border-color: #ccc !important;
}
.btn-white:disabled {
  color: #888 !important;
  background: white !important;
  border-color: #ccc !important;
  cursor: not-allowed !important;
}

button.btn-gray {
  color: #333;
  background: #ccc;
  border-color: #ccc;
}
button.btn-gray:hover,
button.btn-gray:focus,
button.btn-gray:active,
button.btn-gray.active {
  color: #333 !important;
  background: rgba(204, 204, 204, 0.7) !important;
  border-color: #ccc !important;
}
button.btn-gray:disabled {
  color: #333;
  background: #ddd;
  border-color: #ddd !important;
  cursor: not-allowed !important;
}

button.btn-link {
  color: #FFF;
  background: transparent !important;
  border-color: transparent !important;
  outline: none !important;
  padding: 0;
}
button.btn-link:hover,
button.btn-link:focus,
button.btn-link:active,
button.btn-link.active {
  color: #FFF !important;
  background: transparent !important;
  border-color: transparent !important;
  outline: none !important;
}
button.btn-link:disabled {
  color: #333;
  background: transparent;
  border-color: transparent;
  padding: 0;
  cursor: not-allowed !important;
}

button.btn-link-nc {
  /* color: #333; */
  background: transparent;
  border-color: transparent;
  padding: 0;
}
button.btn-link-nc:hover,
button.btn-link-nc:focus,
button.btn-link-nc:active,
button.btn-link-nc.active {
  /* color: #333 !important; */
  background: transparent !important;
  border-color: transparent;
  outline: none;
}
button.btn-link-nc:disabled {
  /* color: #333; */
  background: transparent;
  border-color: transparent;
  padding: 0;
  cursor: not-allowed !important;
}

button.btn-link-plain {
  color: #333;
  border-color: transparent;
  padding: 0;
}
button.btn-link-plain:hover {
  color: #333 !important;
  background: #eaeaea !important;
  border-color: #ccc !important;
}
button.btn-link-plain:focus,
button.btn-link-plain:active,
button.btn-link-plain.active {
  /* color: #333 !important; */
  border-color: transparent;
  outline: none;
}
button.btn-link-plain:disabled {
  color: #333;
  border-color: transparent;
  padding: 0;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  button.btn-block-lg {
    width: 100%;
  }
}

button.btn-vertical {
  height: 100%;
  width: 20px;
  display: inline-block;
  margin-top: -135px;
}
button.btn-vertical:not(:last-child) {
  margin-right: 4px;
}
button.btn-vertical span {
  transform: rotate(-90deg);
  transform-origin: 0% 50%;
  width: 240px;
  margin-top: 240px;
  display: block;
}
div.btn-vertical-3 {
  width: calc(100% - 90px);
}

input.form-control {
  font-size: 14px;
}

select.form-control {
  padding-left: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.check-mini input {
  width: 10px;
  margin-top: 2px;
}
.check-mini label {
  margin-left: -5px;
}

input.form-control:read-only {
  background: white;
}
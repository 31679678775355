body {
  background-color: #fbfcfd !important;
  overflow-x: hidden;
  /* position: absolute; */
}

input:focus,
div:focus,
button:focus,
th:focus {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
}

.triangle-container {
  margin: 0;
  padding-right: 30px;
  width: 100%;
}
.triangle-box {
  background-color: white;
  padding: 20px 10px;
  /* height: 100px; */
  color: #333;
  position: relative;
  border: 1px solid #ced4da;
}
.triangle-arrow-border {
  position: absolute;
  width: 30px;
  bottom: 0px;
  top: 0px;
  right: -31px;
  background-image: linear-gradient(to top right, #ced4da 50%, transparent 51%), linear-gradient(to bottom right, #ced4da 50%, transparent 51%);
  background-size: 100% 50% ;
  background-position: top left, bottom left;
  background-repeat: no-repeat;
}
.triangle-arrow {
  position: absolute;
  width: 30px;
  bottom: 0px;
  top: 0px;
  right: -30px;
  background-image: linear-gradient(to top right, white 50%, transparent 51%), linear-gradient(to bottom right, white 50%, transparent 51%);
  background-size: 100% 50% ;
  background-position: top left, bottom left;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .no-pdright-sm {
    padding-right: 0 !important;
  }
}

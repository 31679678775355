.mxCellEditor.mxPlainTextEditor {
  position: absolute;
  min-width: 10px;
  min-height: 10px;
  background: transparent;
}

.graph-frame {
  width: 100%;
  min-width: 847px;
  height: calc(100vh - 250px);
  padding-left: calc((100% - 847px)/2);
  overflow-y: auto;
  overflow-x: hidden;
}

.graph-container {
  overflow: hidden;
  height: 857px !important;
  width: 847px !important;
  background: white !important;
  /* border: gray dotted 1px; */
  /* background:url('../../public/images/mxresources/grid.gif'); */
}

.toolbar-container {
  width: 840px;
  /* background: #FBFBFB; */
  margin-left: 3px;
  margin-right: 4px;
  margin-bottom: 5px;
  padding: 5px;
  border: #888 solid 2px;
}

/* Dragging Image Params */
img.mxToolbarMode {
  /* margin-top: -18px; */
  /* margin-left: -1px; */
  width: 52px !important;
}

/* Toolbar Image Params */
.mxtoolbar img.mxToolbarMode {
  margin-top: 3px;
  margin-left: 10px;
  margin-bottom: 5px;
  cursor: pointer !important;
}
/* TODO: adjust dragging margin for each symbol */
/* .mxtoolbar img.mxToolbarMode:first-child { margin-top: -30px; } */
/* .mxtoolbar img.mxToolbarMode:nth-child(2) { margin-top: -30px; } */
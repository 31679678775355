.orgstruct-container {
  min-height: 337px !important;
}

.orgstruct-container button {
  width: 130px;
  height: 50px !important;
  font-size: 9.5px !important;
  color: black !important;
  line-height: 1.2 !important;
  border: none !important;
  overflow: hidden;
}

button.btn-link.yellow {
  background: #ffd68a !important;
}
button.btn-link.green {
  background: #a9ce98 !important;
}
button.btn-link.blue {
  background: #a2b3dc !important;
}
button.btn-link.orange {
  background: #eb7c31 !important;
}

button.btn-link.r1 {
  background: #1f376a !important;
  color: white !important;
}
button.btn-link.r3Direct {
  background: #8799b3 !important;
  color: white !important;
}
button.btn-link.r3Support {
  background: #b1c8e7 !important;
  color: white !important;
}
button.btn-link.r4 {
  background: #a2b3dc !important;
  color: white !important;
}

/* button.btn-link.opaque { opacity: 1; } */

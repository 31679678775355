/* https://stackoverflow.com/questions/28417056/how-to-target-only-ie-any-version-within-a-stylesheet */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */

  .ie-margin-top-50 {
    margin-top: 50px;
  }

  .form-control {
    padding-top: 0.18rem;
    padding-bottom: 0.28rem;
  }

  .react-datepicker__input-container {
    padding-top: 0px;
  }
}
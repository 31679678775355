.header-text { font-size: 30px; }

@media (max-width: 768px) {
	.header-text { font-size: 20px; }
}
@media (max-width: 768px) {
	.columns {
		width: 100%
	}
}

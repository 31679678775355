.tooltip .tooltip-inner {
  background-color: rgb(86, 204, 242);
}

/* https://stackoverflow.com/a/50127268 */
.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
  border-bottom-color: rgb(86, 204, 242) !important;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
  border-top-color: rgb(86, 204, 242) !important;
}
.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
  border-left-color: rgb(86, 204, 242) !important;
}
.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  border-right-color: rgb(86, 204, 242) !important;
}

.tooltip-inner {
  text-align: left !important;
}

div.dynamic-tooltip > p {
  margin-bottom: 0;
}
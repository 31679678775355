.vc-lv2 div {
  padding-left: 4px;
  padding-right: 0;
}

@media (max-width: 479px) {
  .vc-lv2 {
    margin-top: 200px !important;
    margin-bottom: 200px !important;
  }
}
@media (min-width: 480px) and (max-width: 539px) {
  .vc-lv2 {
    margin-top: 155px !important;
    margin-bottom: 155px !important;
  }
}
@media (min-width: 540px) and (max-width: 599px) {
  .vc-lv2 {
    margin-top: 82px !important;
    margin-bottom: 82px !important;
  }
}
@media (min-width: 600px) and (max-width: 767px) {
  .vc-lv2 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .vc-lv2 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
}

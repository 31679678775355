.sidenav---sidenav---_2tBP {
  background: #FFF !important;
  border-right: 1px solid #ced4da;
  position: fixed !important;
}

.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
  background-color: #333 !important;
}

main {
  margin-left: 80px;
  margin-right: 16px;
}
@media (min-width: 1200px) {
  main.sidebar-expanded {
    margin-left: 228px;
  }
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  min-width: 212px !important;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL,
.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.menu-lg-visible { display: none; }
.menu-lg-hidden { display: block; }
@media (min-width: 1200px) { 
  .menu-lg-visible { display: block !important; }
  .menu-lg-hidden { display: none !important; } 
}

@media (max-width: 768px) {
  .menu-w-micro { display: none; }
}
@media (max-height: 535px) {
  .menu-h-micro { display: none; }
}

.newdoc-quill .ql-container {
  /* height: 156px !important; */
  height: 500px !important;
  overflow: visible;
}
.newdoc-quill .quill-blur .ql-toolbar {
  display: none;
}
.newdoc-quill .quill-blur .ql-container {
  border: 1px solid #ccc !important;
  height: 200px !important;
}

.ql-picker-label {
  margin-top: -3px;
}

div.ql-tooltip.ql-editing {
  z-index: 999;
}

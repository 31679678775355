.react-bootstrap-table thead th {
  border-top: none;
}

/* Pagination */
.react-bootstrap-table-pagination-list ul {
  float: right;
}

.react-bootstrap-table-pagination-list-hidden {
  display: none;
}

.page-link {
  color: #333 !important;
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
}
.page-item.active .page-link {
  background-color: unset !important;
  border-color: rgb(56, 47, 116) !important;
}

.react-bootstrap-table table {
  font-size: 14px;
}

.react-bootstrap-table-pagination-total {
  font-size: 14px;
}

table .filter-label {
  width: 100%;
}

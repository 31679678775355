@media (max-width: 575px) {
  .xs-textalign-center {
    text-align: center !important;
  }

  .xs-hidden {
    visibility: hidden !important;
  }
}

@media (min-width: 992px) {
  .lg-no-padding-left {
    padding-left: 0 !important;
  }
  .lg-no-padding-right {
    padding-right: 0 !important;
  }
}

.react-datepicker__input-container input {
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.react-datepicker {
  border: 1px solid #ced4da;
}

.react-datepicker__header {
  border-bottom: 1px solid #ced4da;
}

.react-datepicker__input-container input {
  font-size: 14px;
  height: 30px;
  margin-top: 2px;
  padding-left: 8px;
  color: #333;
}
.react-datepicker__input-container input:disabled {
  background: #E9ECEF;
}

.react-datepicker-block, 
.react-datepicker-block div.react-datepicker-wrapper, 
.react-datepicker-block div.react-datepicker__input-container,
.react-datepicker-block div.react-datepicker__input-container input {
   width: 100%;
}